import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import classNames from 'classnames'

const SocialIcons = ({className, items}) => {
  return (
    <div className={classNames('text-left mb-[8px] md:mr-[30px] flex justify-center md:justify-start', className)}>
      {
        items.map((item) => {
          return (
            <GatsbyLink key={item.name} to={item.href} className="text-white font-bold text-[16px] leading-[30px] mr-[18px]" aria-label={item.name}>
              <item.icon className="w-6 h-6 text-[#417aad] hover:text-[#86b749]" />
            </GatsbyLink>
          )
        })
      }
    </div>
  )
}

export {SocialIcons}