import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import {
  Container,
  Link,
  LinkButton,
  NavBurger,
  NavGroup,
  Section,
  FooterContent,
  Prose,
  BottomFooter,
  Seo,
  Button,
  SearchIcon
} from "@components/core";
import { ApproovLogo, ApproovLogoWhite } from "../approov";
import { CookiesConsent } from "../cookies";
import { MenuIcon } from "../../core/icons/menu";

import { headerItems, footerItems } from "./layout-data";

import "./header-nav-bar.css";
import { SemiFooter } from "../home/semi-footer";
import { BlogGrid } from "../blog/blog-grid";
import { Script } from "gatsby";

const sideItems = [...headerItems.upper, ...headerItems.lower];

// to use a headroom header, replace the sticky div with headroom

const PageLayout = (props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [tag, setTag] = useState();
  const isSticky = true;

  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const GSE_API_KEY = "AIzaSyB3LRtdIwW-sC1nW9GJnUsi1DMzpr-0p6c";
  const GSE_ENGINE_ID = "f46dbe0499b594328";

  const searchContainerRef = useRef(null);

  // const [isSticky, setIsSticky] = useState(true)

  // const changeSticky = (e) => {
  //   if (window.scrollY>112&&!isSticky) {
  //     setIsSticky(true);
  //   } else if(window.scrollY<=112&&isSticky) {
  //     setIsSticky(false);
  //   }
  // }
  // useEffect(()=>{
  //   setIsSticky(window.scrollY>112);

  //   window.addEventListener('scroll', changeSticky);
  //   return () => {
  //       window.removeEventListener('scroll', changeSticky);
  //   };
  // }, [isSticky])

  const handleSearch = async() => {
    if (!searchTerm) return;
    setIsLoading(true);
    const url = `https://www.googleapis.com/customsearch/v1?key=${GSE_API_KEY}&cx=${GSE_ENGINE_ID}&q=${searchTerm}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      setSearchResult(data.items);
    } catch (error) {
      console.error('Failed to fetch the search results:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const changeTag = (tag) => {
    setTag(tag);
  };

    useEffect(() => {
      if (searchBarOpen) {
        const handleClickOutside = (event) => {
          if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
            setSearchBarOpen(false);
          }
        };
  
        const handleScroll = (e) => {
          if (document.querySelector('.search-results') && document.querySelector('.search-results').contains(e.target)) {
            e.stopPropagation();
          } else {
            e.preventDefault();
          }
        };
  
        document.body.style.overflow = 'hidden';
        document.addEventListener("mousedown", handleClickOutside);
        window.addEventListener('wheel', handleScroll, { passive: false });
  
        return () => {
          document.body.style.overflow = '';
          document.removeEventListener("mousedown", handleClickOutside);
          window.removeEventListener('wheel', handleScroll);
        };
      }
    }, [searchBarOpen]);

  const handleSearchTextInput = (searchKey) => {
    setSearchTerm(searchKey);
    if (searchKey.length >=4 ) {
      handleSearch();
    }
  }

  return (
    <>
      <Seo name={props.pathname} onChangeTag={changeTag} />
      <NavBurger
        logo={ApproovLogo}
        items={sideItems}
        show={sidebarOpen}
        setOpen={setSidebarOpen}
      />

      <div className="flex flex-col w-full min-h-screen">
        <div
          className={classNames("z-10", {
            "bg-white sticky top-0 transition ease-in duration-300 transform":
              isSticky,
          })}
        >
          <div className="text-white bg-[#86b146] text-center">
            <Link className="text-white" href="https://approov.io/info/careers">
              We're Hiring!
            </Link>
          </div>
          <Section
            as="header"
            className={classNames(" shadow-sm", {
              "bg-white shadow-gray-500 transition ease-in duration-300 transform":
                isSticky,
              "bg-transparent": false,
            })}
          >
            <Container className="container-lg">
              <div className="bg-transparent flex items-center justify-between flex-shrink-0 w-full h-16 bg-white lg:h-[7rem]">
                <div className="flex justify-start w-24 lg:hidden">
                  <button
                    type="button"
                    className="mx-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <MenuIcon className="w-8 h-8" aria-hidden="true" />
                  </button>
                </div>
                <Link to="/">
                  <ApproovLogo
                    className="!h-auto md:ml-[8px] lg:mt-[16px] mr-4 sm:w-[7.5rem] md:w-[8.5rem] lg:w-[11rem]"
                    isSticky={isSticky}
                  />
                </Link>
                <div className="flex flex-col items-end mt-0 lg:mt-[20px] pr-[4px] sm:pr-[16px] md:pr-[25px]">
                  <div className="flex items-center justify-end">
                    <NavGroup
                      className="hidden lg:inline-flex lg:mr-3 bg-transparent"
                      items={headerItems.upper}
                    />
                  </div>
                  <div className="flex items-center w-full mt-[30px]">
                    <NavGroup
                      className="hidden lg:inline-flex bg-transparent flex justify-evenly items-center w-full"
                      items={headerItems.lower}
                    />
                    <div className="relative p-5" ref={searchContainerRef}>
                      <div className="flex justify-center text-center">
                        <SearchIcon 
                          className="w-6 h-6 cursor-pointer" 
                          aria-hidden="true" 
                          onClick={() => setSearchBarOpen(!searchBarOpen)}
                        />
                      </div>
                      {searchBarOpen && (
                        <div className="absolute top-[90%] left-30 right-0 z-[1000]">
                          <div className="inline-flex bg-white p-2">
                            <input
                              type="text"
                              value={searchTerm}
                              onChange={(e) => handleSearchTextInput(e.target.value)}
                              onKeyDown={(e)=> {
                                if (e.key === 'Enter') {
                                  handleSearch();
                                }
                              }}
                              placeholder="E.g. Approov..."
                              className="border border-gray-300 rounded-md mr-3 ml-4 w-full min-w-[15rem] sm:w-[15.25rem] md:w-[35rem] lg:w-[35rem]"
                            />
                            <Button className="free-trial text-[16px] button-secondary button-xs bg-[#86b146] w-max font-bold pr-5 pl-5 mr-4"
                              onClick={handleSearch}>
                              Search
                            </Button>
                          </div>
                          <div>
                            {isLoading ? (
                              <div className="search-results w-full bg-white p-4 rounded-br-lg rounded-bl-lg pr-[0.5rem]">
                                <p>Loading...</p>
                              </div>
                            ) : (
                              searchResult && searchResult.length !== 0 && 
                                <div className="search-results w-full bg-white p-4 rounded-br-lg rounded-bl-lg pr-[0.5rem]">
                                  <ul className="max-h-[35rem] overflow-y-auto">
                                    {searchResult.map((item, index) => (
                                      <li key={index} className="border-b border-gray-300 last:border-b-0 pb-2 pt-2 first:pt-0">
                                        <a href={item.link} target="_blank" rel="noopener noreferrer" className="block hover:bg-gray-100 focus:bg-gray-100 text-blue-500 hover:text-blue-700">
                                          <div className="p-2 flex">
                                            <div>
                                              <div className="font-medium">{item.title}</div>
                                              <p className="text-sm text-[#404041]">{item.snippet}</p>
                                            </div>
                                          </div>
                                        </a>
                                      </li>
                                    ))}
                                    <li className="text-center mt-4">
                                      <a
                                        href={`https://www.google.com/search?q=site:approov.io+${encodeURIComponent(searchTerm)}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:text-blue-700 inline-block"
                                      >
                                        More results on Google
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-center text-center w-24 lg:w-auto">
                      <LinkButton
                        className="free-trial text-[16px] button-secondary button-xs bg-[#86b146] w-max font-bold"
                        href="https://approov.io/info/schedule-a-demo"
                      >
                        Request Demo
                      </LinkButton>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </Section>
        </div>

        <Prose className="flex grow">
          <main className="w-full">{props.children}</main>
        </Prose>
        {/* Restrict blog to other page except gaming */}
        {tag && (props.pathname == '/industry/mobile-gaming-and-gambling/' || props.pathname == '/industry/mobile-finance/') && (
          <Section className="py-[84px] bg-[#f2f5f9] text-center">
            <Container>
              <BlogGrid tag={tag} />
            </Container>
          </Section>
        )}
        <SemiFooter></SemiFooter>
        <footer
          className="page-footer text-center bg-[#004360] py-[2.5rem]"
          style={{ boxShadow: "rgb(42 50 57 / 10%) 1px -9px 17px 0px" }}
        >
          <div className="container">
            <div className="flex justify-center md:justify-start">
              <Link href="/">
                <ApproovLogoWhite className="md:mt-[10px] md:ml-[18px] w-[200px]" />
              </Link>
            </div>
            <FooterContent items={footerItems} />
            <BottomFooter items={footerItems} />
          </div>
        </footer>
      </div>
      {/*Start of HubSpot Embed Code*/}
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/2449407.js"
      ></Script>
      {/*End of HubSpot Embed Code*/}
      <CookiesConsent />
    </>
  );
};

export { PageLayout };