import React from 'react'
import classNames from 'classnames'

const CommonColumnHeader = React.memo(({title, className, ...rest}) => {
  return (
    <div className={classNames("flex content-center w-full", className)} {...rest}>
      <div className="">
        <p className={classNames("bg-white flex justify-center font-bold pr-[10px] whitespace-nowrap text-[#86b146] text-[13.3px] md:text-[19.5px]")}>{title}</p>
      </div>
      <div className="flex content-center w-full flex-wrap">
          <div className="w-full bg-[#86b146] h-[4px]"></div>
      </div>
    </div>
  )
})

export default CommonColumnHeader;