import React from 'react'
import loadable from '@loadable/component';
// import { QuickstartGrid } from '../quickstarts/quickstart-grid';
import { environments } from './table-context'

const QuickstartGrid = loadable(() => import('../quickstarts/quickstart-grid'))

const DevEnvironment = ({ className, ...rest }) => {

  return (
    <div className="mt-[19px] sm:mt-[40px]">

      <table>
        <tbody>
        {environments.map((item, index) =>
        <React.Fragment key={"environments" + index}>
          <tr >
            <td className="columnHeader pl-[4px] " colSpan={3}>
              <p className="text-center md:text-left text-[12px] md:text-[18px] mb-[10px]">{item.bigTitle}</p>
              <p className="text-center md:text-left text-[11px] md:text-[17px]">{item.smallTitle}</p>
            </td>
          </tr>
          <tr>
            <td className="w-[4%]"/>
            <td className="w-[33%] align-baseline rowHeader pl-[4px]">
              <p className="text-[12px] md:text-[18px]">{item.bigTitle}</p>
              <p className="text-[11px] md:text-[17px]">{item.smallTitle}</p>
            </td>
            <td className="w-[63%]">
              {/* <div className="flex flex-wrap justify-center">
                {item.logos.map((logo) => {
                  let file = "./images/" + logo.file
                  console.log('filename', file)

                  return(
                    <div key={logo.label} className="border-[1px] border-grey p-[5px] w-[84px] h-[79px] mx-[1px] my-[5px]">
                      <div className="h-[37px] w-[74px]">
                        {logo.file}
                      </div>
                      <p className="text-[9.3px] text-center">{logo.label}</p>
                    </div>
                  )
                }
                )}
              </div> */}
              <QuickstartGrid tag={item.tag} displayMode="sm"/>
            </td>
          </tr> 
          {
          item.nospliter
          ?
          <></>
          :
          <tr>
            <td className="w-full" colSpan={3}>
              <hr className="bg-grey border-grey w-full m-auto my-5"></hr>
            </td>
          </tr>
          }
        </React.Fragment>
        )}
        </tbody>
      </table>
    </div>
  )
}

export default DevEnvironment;