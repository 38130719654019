import React from "react";
import classNames from "classnames";
import { ResourceCard } from "./resource-card";
import { useResource } from "./resource-context";

export const ResourceGrid = ({
  className,
  tag,
  onSelect,
  disabled = false,
  ...rest
}) => {
  const { resources } = useResource();

  return (
    <div className={classNames("w-full", className)} {...rest}>
      <div className="flex flex-wrap items-stretch justify-center gap-4 gap-y-8 lg:gap-8 lg:gap-y-12">
        {resources
          .filter((resource) => resource.tags?.includes(tag))
          .map((resource) => (
            <ResourceCard
              key={resource.title}
              resource={resource}
              onSelect={onSelect}
              disabled={disabled}
            />
          ))}
      </div>
    </div>
  );
};
