
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const ApproovLogo = (props) => (
  <StaticImage
    objectFit="cover"
    src="./approov-logo.png"
    alt="Approov"
    placeholder="blurred"
    className={classNames(
      `h-[4rem] w-[7rem] ${props.className}`,
      {
        'transition ease-in delay-250 scale-90 duration-600': props.isSticky
      }
    )}
    style={props.style}
  />
)

export { ApproovLogo }
