import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

const PricingBanner = React.memo(({ title, subtitle, nounderline, ...rest }) => (
  <>
    <div className="bg-[#122331] w-full min-h-[327px] h-[48vw] max-h-[474px] flex item-center md:block">
      <div className="container flex">
        <div className="w-full md:w-1/2 flex justify-center  relative mt-[-20px] md:mt-[110px]">
          <h1 className="text-[35px] md:text-[58px] leading-[58px] font-black self-center text-center tracking-tight mt-[65px] md:ml-[-53px] text-[#acafb3]">
            <span className="pb-[29px] ">{title}</span>
            {!nounderline && <hr className="border-[3.1px] bg-[#b91684] border-[#b91684] w-[114%] m-auto ml-[-6px] md:ml-[-11px] mt-[21px]"/>}
          </h1>
        </div>
        <div className="hidden md:block w-full md:w-1/2 md:ml-[-80px] md:mt-[91px] not-prose">
          <StaticImage
            loading='lazy'
            src={`./images/pricing.png`}
            className="md:w-[500px] h-auto"
            alt="Mobile App Protection Security Price | Approov"
          />
        </div>
      </div>
    </div>
  </>
))

export default PricingBanner
